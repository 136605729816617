<template>
  <div v-if="!marketingMaterialLoader" class="pa-4">
    <v-card class="pa-4 h-full rounded border">
      <div v-if="!insertImageUi" class="d-flex justify-space-between">
        <div
          class="d-flex grid sm:grid-cols-1 btwn_sm-md:grid-cols-2 md:grid-cols-3"
        >
          <div class="d-flex mt-1 items-center">
            <!-- <button
                type="button"
                class="bg-blue-500 text-white h-10 w-[120px] cursor-pointer rounded text-xs ml-4"
                @click="insertImage()"
              >
                + Insert Image
              </button> -->
          </div>
        </div>
        <div class="download">
          <!-- <button
              type="button"
              class="bg-blue-500 text-white h-10 w-[120px] cursor-pointer rounded text-xs ml-10"
              @click="downloadUnicodeCSV(getUserDetails, 'User Details', header)"
            >
              Download
            </button> -->

          <!-- <v-btn v-if="!insertImageUi"
                    
                    depressed
                    @click="insertImage()"
                    height="26"
                    class="fsize12 text-capitalize mr-2"
                    
                    :color="'primary'"
                    >+ Insert Image</v-btn
                  > -->
        </div>
      </div>

      <div
        class="border mt-4 rounded overflow-auto"
        v-if="!insertImageUi && marketingMaterialData.length > 0"
      >
        <v-card class="ma-4 cardBoxShadow rounded-lg">
          <v-simple-table id="tableData" class="border-right-left">
            <thead>
              <tr class="tableRow">
                <th class="tableHeader">Tag</th>
                <th class="tableHeader">Theme</th>

                <th class="tableHeader text-center">Image URL</th>
                <th class="tableHeader text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="tableRow"
                v-for="(item, index) in filteredMarketingMaterialData()"
                :key="index"
              >
                <td class="tableContent py-2">
                  {{ item.tag }}
                </td>
                <td class="tableContent">{{ item.theme }}</td>

                <td class="tableContent text-center">
                  <img
                    class="w-auto max-w-150px h-24 mx-auto"
                    v-if="item.imageUrl"
                    :src="item.imageUrl"
                    alt="#image"
                  />
                </td>
                <td class="text-center min-w-120">
                  <v-icon class="pr-4" medium @click="editMaterial(item)">
                    mdi-pencil
                  </v-icon>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card>
      </div>
      <div v-if="insertImageUi">
        <Add_edit_materials :currentTab="currentTab"></Add_edit_materials>
      </div>
    </v-card>
  </div>
</template>
<script>
import { mapState } from "vuex";
import Add_edit_materials from "./add_edit_materials.vue";

export default {
  name: "marketingMaterials",
  props: {
    currentTab: String,
  },
  data: () => ({
    header: [
      { name: "Title" },
      { name: "Sub Title" },
      { name: "Button Name" },
      { name: "Button Color" },
      { name: "Button URL" },
      { name: "Card Color" },
      { name: "Card Value" },
      { name: "Image URL" },
    ],
  }),
  computed: {
    ...mapState("uploadFile", [
      "insertImageUi",
      "marketingMaterialData",
      "marketingMaterialLoader",
    ]),
  },
  components: {
    Add_edit_materials,
  },
  methods: {
    insertImage() {
      this.$store.commit("uploadFile/openInsertImageInput", true);
    },
    editMaterial(item) {
      this.$store.commit("uploadFile/setMarketingEditData", item);
      this.$store.commit("uploadFile/setIsMarketingEdit", true);
      this.$store.commit("uploadFile/openInsertImageInput", true);
    },
    deleteMaterial(item) {
      if (item.id) {
        let json = {
          id: item.id.toString(),
        };
        this.$store.dispatch("uploadFile/deleteMarketingMaterial", json);
      }
    },
    filteredMarketingMaterialData() {
      return this.marketingMaterialData.filter((el) => {
        return this.currentTab.includes(el.pageLocation);
      });
    },
  },
  created() {
    this.$store.dispatch("uploadFile/getMarketingMaterial");
  },
  destroyed() {
    this.$store.commit("uploadFile/openInsertImageInput", false);
  },
};
</script>
<style>
.bg-blue-500 {
  background-color: rgb(64, 64, 239);
}
.max-w-150px {
  max-width: 150px;
}
.h-24 {
  height: 100px;
}
</style>

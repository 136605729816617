<template>
  <div>
    <div class="d-flex align-center px-4 py-2 mt-2">
      <div class="w-100">
        <div class="font-weight-bold primaryColor">
          File Upload
          <v-progress-circular
            class="ml-2"
            indeterminate
            v-if="loader || marketingMaterialLoader"
            size="18"
            :width="2"
            color="black"
          ></v-progress-circular>
        </div>
        <div class="pt-3 d-flex justify-space-between">
          <div>
            <v-slide-group v-model="tabSelect" mandatory show-arrows>
              <v-slide-item
                v-for="n in tabs"
                :key="n"
                v-slot="{ active, toggle }"
                :value="n"
              >
                <v-btn
                  :title="n"
                  depressed
                  height="26"
                  class="fsize12 text-capitalize mr-2"
                  @click="toggle"
                  :color="active ? 'primary' : 'grey lighten-3'"
                  >{{ n }}</v-btn
                >
              </v-slide-item>
            </v-slide-group>
          </div>
        </div>
      </div>
    </div>

    <v-divider class="mx-4"></v-divider>

    <!-- POSITIONS UPLOAD -->
    <v-card
      class="ma-4 cardBoxShadow rounded-lg"
      v-if="tabSelect == 'Positions Upload'"
    >
      <v-row no-gutters class="px-4 py-10">
        <v-col cols="12" sm="12" md="6" lg="3">
          <div class="fsize14 font-weight-bold primaryColor pb-4">
            Positions File Upload
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="5">
          <v-radio-group
            row
            v-model="whichOrigin"
            @change="resetAll"
            class="mb-4 pt-0 mt-0"
          >
            <v-radio label="Kambala" value="Kambala"></v-radio>
          </v-radio-group>
          <div class="primaryColor fsize14 pb-1">NSE Futures & Options</div>
          <v-file-input
            :rules="[nfoFileSizeRule]"
            :show-size="true"
            :loading="nfoLoader"
            class="fileInput"
            max-width="450px"
            autofocus
            id="file"
            type="file"
            placeholder="Select file (.xls File only)"
            ref="nseFoFile"
            @click:clear="uploadDoc('clear')"
            @change="uploadDoc(nseFoFile, 'NFO')"
            :multiple="false"
            v-model="nseFoFile"
            accept=".xls"
            outlined
            dense
            append-icon="mdi-folder-open"
            prepend-icon=""
          ></v-file-input>
          <div class="primaryColor fsize14 pb-1">NSE CDS</div>
          <v-file-input
            :rules="[cdsFileSizeRule]"
            :show-size="true"
            :loading="cdsLoader"
            class="fileInput"
            max-width="450px"
            id="file"
            type="file"
            placeholder="Select file (.xls File only)"
            ref="cdsFile"
            @click:clear="uploadDoc('clear')"
            @change="uploadDoc(cdsFile, 'CDS')"
            :multiple="false"
            v-model="cdsFile"
            accept=".xls"
            outlined
            dense
            append-icon="mdi-folder-open"
            prepend-icon=""
          ></v-file-input>
          <div class="primaryColor fsize14 pb-1">MCX</div>
          <v-file-input
            :rules="[mcxFileSizeRule]"
            :show-size="true"
            :loading="mcxLoader"
            class="fileInput"
            max-width="450px"
            id="file"
            type="file"
            placeholder="Select file (.xls File only)"
            ref="mcxFile"
            @click:clear="uploadDoc('clear')"
            @change="uploadDoc(mcxFile, 'MCX')"
            :multiple="false"
            v-model="mcxFile"
            accept=".xls"
            outlined
            dense
            append-icon="mdi-folder-open"
            prepend-icon=""
          ></v-file-input>
        </v-col>
      </v-row>
    </v-card>

    <!-- HOLDINGS UPLOAD -->
    <v-card
      class="ma-4 cardBoxShadow rounded-lg"
      v-if="tabSelect == 'Holdings Upload'"
    >
      <v-row no-gutters class="px-4 py-10">
        <v-col cols="12" sm="12" md="6" lg="3">
          <div class="fsize14 font-weight-bold primaryColor pb-4">
            Holdings File Upload
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="5">
          <v-radio-group
            row
            v-model="whichOrigin"
            @change="resetAll"
            class="mb-4 pt-0 mt-0"
          >
            <v-radio label="Kambala" :ripple="false" value="Kambala"></v-radio>
          </v-radio-group>
          <div v-if="whichOrigin == 'TR'">
            <div class="primaryColor fsize14 pb-1">
              Holdings Price and Qty Upload
            </div>
            <v-file-input
              :loading="hLoader"
              class="fileInput"
              max-width="450px"
              autofocus
              type="file"
              placeholder="Select file"
              ref="holdingFile"
              @click:clear="uploadHoldingsDoc('clear')"
              @change="uploadHoldingsDoc(holdingFile)"
              v-model="holdingFile"
              :multiple="false"
              accept=".xlsx"
              outlined
              dense
              append-icon="mdi-folder-open"
              prepend-icon=""
            ></v-file-input>
          </div>
          <div v-if="whichOrigin == 'Kambala'">
            <div class="primaryColor fsize14 pb-1">DP Holdings</div>
            <v-file-input
              :rules="[dpFileSizeRule]"
              :show-size="true"
              :loading="DPLoader"
              class="fileInput"
              max-width="450px"
              autofocus
              id="file"
              type="file"
              placeholder="Select File (txt... File only)"
              ref="DPFile"
              @click:clear="uploadHoldingsDoc('clear')"
              @change="uploadHoldingsDoc(DPfile, 'DP')"
              :multiple="false"
              v-model="DPfile"
              accept=".txt"
              outlined
              dense
              append-icon="mdi-folder-open"
              prepend-icon=""
            ></v-file-input>
            <div class="primaryColor fsize14 pb-1">T1 Holdings</div>
            <v-file-input
              :rules="[t1FileSizeRule]"
              :show-size="true"
              :loading="T1Loader"
              class="fileInput"
              max-width="450px"
              id="file"
              type="file"
              placeholder="Select File (txt... File only)"
              ref="T1File"
              @click:clear="uploadHoldingsDoc('clear')"
              @change="uploadHoldingsDoc(T1file, 'T1')"
              :multiple="false"
              v-model="T1file"
              accept=".txt"
              outlined
              dense
              append-icon="mdi-folder-open"
              prepend-icon=""
            ></v-file-input>
          </div>
        </v-col>
      </v-row>
    </v-card>

    <!-- Users Upload -->
    <v-card
      class="ma-4 cardBoxShadow rounded-lg"
      v-if="tabSelect == 'Users Upload'"
    >
      <v-row no-gutters class="px-4 py-10">
        <v-col cols="12" sm="12" md="6" lg="3">
          <div class="fsize14 font-weight-bold primaryColor pb-4">
            Users Upload
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="5">
          <div class="d-flex">
            <div class="w-100">
              <div class="d-flex justify-space-between pr-10">
                <div class="primaryColor fsize14 pb-1">Users upload</div>
                <div class="fsize12 secondaryColor">
                  Note : accept only .csv file format
                </div>
              </div>
              <v-file-input
                :rules="[userFileSizeRule]"
                :show-size="true"
                :loading="userFLoader"
                class="fileInput"
                max-width="450px"
                type="file"
                placeholder="Select file"
                ref="userFile"
                @click:clear="uploadUserFile('clear')"
                @change="uploadUserFile(userFile)"
                v-model="userFile"
                :multiple="false"
                accept=".csv"
                outlined
                dense
                append-icon="mdi-folder-open"
                prepend-icon=""
              ></v-file-input>
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="4">
          <div class="d-flex w-100 justify-end">
            <a class="fsize14 underline-none" href="Template.csv" download
              >Download Template</a
            >
          </div>
        </v-col>
      </v-row>
    </v-card>

    <div
      class="mt-5 pa-4 d-flex justify-end"
      v-if="tabSelect == 'Holdings Upload'"
    >
      <v-btn
        height="40"
        width="96"
        depressed
        outlined
        color="grey--text"
        class="mr-3 text-capitalize"
        >Cancel</v-btn
      >
      <v-btn
        :loading="loading"
        height="40"
        width="96"
        depressed
        color="primary"
        @click="insertHoldingsFile()"
        class="mr-3 text-capitalize"
        >Submit</v-btn
      >
    </div>
    
    <div v-if="tabSelect == 'Holdings Upload'" class="pa-4">
      <v-card class="pa-6 d-flex align-center">
        Re-push from SFTP   <v-btn
        :loading="repushloader"
        height="40"
        width="96"
        depressed
        color="primary"
        @click="$store.dispatch('uploadFile/callRepushHoldings')"
        class="ml-5 text-capitalize"
        >Re-push</v-btn
      >
      </v-card>
    </div>

    <div
      class="mt-5 pa-4 d-flex justify-end"
      v-if="tabSelect == 'Positions Upload'"
    >
      <v-btn
        height="40"
        width="96"
        depressed
        outlined
        color="grey--text"
        class="mr-3 text-capitalize"
        >Cancel</v-btn
      >
      <v-btn
        height="40"
        width="96"
        depressed
        color="primary"
        @click="insertFile('check')"
        class="text-capitalize fsize16"
        >Submit</v-btn
      >
    </div>

    <div
      class="mt-5 pa-4 d-flex justify-end"
      v-if="tabSelect == 'Users Upload'"
    >
      <v-btn
        height="40"
        width="96"
        depressed
        outlined
        color="grey--text"
        class="mr-3 text-capitalize"
        >Cancel</v-btn
      >
      <v-btn
        :loading="userfBtnLoader"
        height="40"
        width="96"
        depressed
        color="primary"
        @click="insertUsersFile('check')"
        class="text-capitalize fsize16"
        >Submit</v-btn
      >
    </div>
    <div
      v-if="
        tabSelect == 'Login Banner' ||
        tabSelect == 'Signup Banner' ||
        tabSelect == 'Initial Page'
      "
    >
      <marketingMaterial :currentTab="tabSelect"></marketingMaterial>
    </div>
    <!-- dialog form reset confirmation -->
    <v-dialog v-model="cacheResetDialog" max-width="350" height="250">
      <v-card class="pa-3 cardBoxShadow rounded-lg">
        <div class="pa-4 fsize13 secondaryColor">
          Are you sure want to reset the caches ?
        </div>
        <div class="d-flex mt-4">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            depressed
            outlined
            class="text-capitalize mr-2"
            @click="resetCache()"
            >Confirm</v-btn
          >
          <v-btn
            text
            depressed
            outlined
            class="text-capitalize"
            @click="cacheResetDialog = false"
            >Cancel</v-btn
          >
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmationDialog" max-width="400" height="300">
      <v-card class="pa-3 cardBoxShadow rounded-lg">
        <div class="pa-4 fsize14 primaryColor">
          Could you make sure you are Uploading a
          <strong>{{ whichOrigin }}</strong> positions file ?
        </div>
        <div class="d-flex mt-4">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :loading="loading"
            depressed
            class="text-capitalize mr-2"
            @click="insertFile('confirm')"
            >Confirm</v-btn
          >
          <v-btn
            text
            depressed
            outlined
            class="text-capitalize"
            @click="$store.commit('uploadFile/SET_confirmationDialog', false)"
            >Cancel</v-btn
          >
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import marketingMaterial from "./marketingMaterial.vue";
export default {
  data: () => ({
    loader: false,
    cdsFile: null,
    nseFoFile: null,
    mcxFile: null,
    DPfile: null,
    T1file: null,
    // tabs: ["Positions Upload", "Holdings Upload", "Add New Scrip"],
    // tabs: ["Positions Upload", "Holdings Upload", "Add New Scrip","Users Upload"],
    //tabs: ["Positions Upload", "Holdings Upload", "Users Upload"],
    tabs: [
      "Positions Upload",
      "Holdings Upload",
      "Users Upload",
      "Login Banner",
      "Signup Banner",
      "Initial Page",
    ],
    tabSelect: "Positions Upload",

    // add script models
    exchange: "NSE",
    exchangeSegment: "",
    symbolName: "",
    lotSize: Number,
    priceRangeTo: Number,
    token: Number,
    tickSize: Number,
    groupName: "",
    companyName: "",
    priceRangeFrom: Number,
    eqItems: ["NSE", "BSE", "NFO", "BFO", "MCX", "CDS", "BCD"],
    eqSegItems: ["nse_cm", "bse_cm"],
    cacheResetSwitch: false,
    cacheResetDialog: false,

    //
    holdingFile: null,
    poaFile: null,

    whichOrigin: "Kambala",
    userFile: null,
  }),
  components: {
    marketingMaterial,
  },
  computed: {
    ...mapGetters({ loading: "getLoader" }),
    ...mapGetters("uploadFile", {
      hLoader: "getHLoader",
      nfoLoader: "getNfoLoader",
      cdsLoader: "getCdsLoader",
      mcxLoader: "getMcxLoader",
      poaLoader: "getPoaLoader",
      DPLoader: "getDPFileLoader",
      T1Loader: "getT1FileLoader",
      userFLoader: "getUserfLoader",
      userfBtnLoader: "getUserfBtnLoader",
    }),
    ...mapState("uploadFile", [
      "confirmationDialog",
      "marketingMaterialLoader", "repushloader"
    ]),
  },
  watch: {
    cacheResetDialog(val) {
      this.cacheResetSwitch = false;
    },
  },
  methods: {
    resetAll() {
      this.cdsFile = null;
      this.nseFoFile = null;
      this.mcxFile = null;
      this.holdingFile = null;
      this.poaFile = null;
    },

    uploadDoc(file, exch) {
      if (file == "clear" || file == null) return;
      if (
        this.nfoFileSizeRule() == "File size exceeds the limit (30MB)." &&
        exch == "NFO"
      )
        return;
      if (
        this.cdsFileSizeRule() == "File size exceeds the limit (30MB)." &&
        exch == "CDS"
      )
        return;
      if (
        this.mcxFileSizeRule() == "File size exceeds the limit (30MB)." &&
        exch == "MCX"
      )
        return;
      let formData = new FormData();
      formData.append("file", file);
      this.whichOrigin == "Kambala"
        ? formData.append("exchange", exch)
        : formData.append("exch", exch);
      let payload = {
        formData: formData,
        exch: exch,
      };
      this.whichOrigin == "Kambala"
        ? this.$store.dispatch("uploadFile/uploadKambalaFiles", payload)
        : this.$store.dispatch("uploadFile/uploadFile", payload);
    },

    async insertFile(where) {
      if (
        this.nfoFileSizeRule() == "File size exceeds the limit (30MB)." &&
        exch == "NFO"
      )
        return;
      if (
        this.cdsFileSizeRule() == "File size exceeds the limit (30MB)." &&
        exch == "CDS"
      )
        return;
      if (
        this.mcxFileSizeRule() == "File size exceeds the limit (30MB)." &&
        exch == "MCX"
      )
        return;

      if (
        this.cdsFile != null &&
        this.nseFoFile != null &&
        this.mcxFile != null
      ) {
        if (where == "check") {
          this.$store.commit("uploadFile/SET_confirmationDialog", true);
        }
        if (where == "confirm") {
          this.whichOrigin == "Kambala"
            ? await this.$store.dispatch("uploadFile/insertKambalaFiles")
            : await this.$store.dispatch("uploadFile/insertFile");
          this.cdsFile = null;
          this.nseFoFile = null;
          this.mcxFile = null;
        }
      } else {
        this.$store.commit("SET_SNAKBAR", {
          msg: "Please Upload All Documents",
          show: true,
          color: "red lighten-1",
          timeout: 2500,
        });
      }
    },

    uploadHoldingsDoc(file, type) {
      if (file == "clear" || file == null) return;
      if (
        this.dpFileSizeRule() == "File size exceeds the limit (30MB)." &&
        type == "DP"
      )
        return;
      if (
        this.t1FileSizeRule() == "File size exceeds the limit (30MB)." &&
        type == "T1"
      )
        return;
      let formData = new FormData();
      formData.append("file", file);
      // kambala holding
      if (this.whichOrigin == "Kambala") {
        formData.append("type", type);
        this.$store.dispatch("uploadFile/uploadKambalaHoldingsFile", {
          formData: formData,
          type: type,
        });
      } else {
        this.$store.dispatch("uploadFile/loadHoldingFile", formData);
      }
    },

    async insertHoldingsFile() {
      if (
        this.dpFileSizeRule() == "File size exceeds the limit (30MB)." &&
        type == "DP"
      )
        return;
      if (
        this.t1FileSizeRule() == "File size exceeds the limit (30MB)." &&
        type == "T1"
      )
        return;

      if (this.holdingFile != null && this.whichOrigin == "TR") {
        await this.$store.dispatch("uploadFile/insertHoldingsFile");
        this.holdingFile = null;
      } else if (
        (this.DPfile != null || this.T1file != null) &&
        this.whichOrigin == "Kambala"
      ) {
        await this.$store.dispatch("uploadFile/insertKambalaHoldingsFile");
        this.DPfile = null;
        this.T1file = null;
      } else {
        this.$store.commit("SET_SNAKBAR", {
          msg: "Please Upload Document",
          show: true,
          color: "red lighten-1",
          timeout: 2500,
        });
      }
    },

    async insertPoaFile() {
      if (this.poaFile != null) {
        await this.$store.dispatch("uploadFile/insertPoaFile");
        this.poaFile = await [];
      } else {
        this.$store.commit("SET_SNAKBAR", {
          msg: "Please Upload Document",
          show: true,
          color: "red lighten-1",
          timeout: 2500,
        });
      }
    },

    resetAllScripForm() {
      this.exchange = "NSE";
      this.exchangeSegment = "";
      this.symbolName = "";
      this.lotSize = null;
      this.priceRangeTo = null;
      this.token = null;
      this.tickSize = null;
      this.groupName = "";
      this.companyName = "";
      this.priceRangeFrom = null;
    },
    restrictDotOperator(evt) {
      evt.key == "." && evt.keyCode == 46 ? evt.preventDefault() : "";
    },
    allowTwoDigitsAfterDecimal(e) {
      var t = e.target.value;
      e.value =
        t.indexOf(".") >= 0
          ? t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)
          : t;
    },
    async resetCache() {
      this.$store.dispatch("scripInfo/resetCache").then((res) => {
        this.cacheResetDialog = false;
      });
    },
    async addScrip() {
      let JsonObj = {
        exch: this.exchange,
        exchSeg: this.exchangeSegment,
        groupName: this.groupName,
        symbol: this.symbolName,
        token: this.token,
        companyName: this.companyName,
        lotSize: this.lotSize,
        tickSize: this.tickSize,
        priceRangeFrom: this.priceRangeFrom,
        priceRangeTo: this.priceRangeTo,
      };
      await this.$store.dispatch("scripInfo/scripAdd", JsonObj).then((res) => {
        this.resetAll();
      });
    },
    async insertPOAFile() {
      this.$store.dispatch("scripInfo/insertPoaFile").then((res) => {
        this.resetAll();
      });
    },

    async uploadUserFile(file, type) {
      if (file == "clear" || file == null) return;
      if (this.userFileSizeRule() == "File size exceeds the limit (30MB).")
        return;
      let formData = new FormData();
      formData.append("file", file);
      this.$store.dispatch("uploadFile/uploadUsersFile", {
        formData: formData,
      });
    },

    async insertUsersFile(param) {
      if (this.userFileSizeRule() == "File size exceeds the limit (30MB).")
        return;
      await this.$store.dispatch("uploadFile/insertUsersFile");
      this.userFile = null;
    },

    nfoFileSizeRule() {
      if (this.nseFoFile?.size > 30 * 1024 * 1024) {
        return "File size exceeds the limit (30MB).";
      } else {
        return true;
      }
    },

    cdsFileSizeRule() {
      if (this.cdsFile?.size > 30 * 1024 * 1024) {
        return "File size exceeds the limit (30MB).";
      } else {
        return true;
      }
    },

    mcxFileSizeRule() {
      if (this.mcxFile?.size > 30 * 1024 * 1024) {
        return "File size exceeds the limit (30MB).";
      } else {
        return true;
      }
    },

    dpFileSizeRule() {
      if (this.DPfile?.size > 30 * 1024 * 1024) {
        return "File size exceeds the limit (30MB).";
      } else {
        return true;
      }
    },

    t1FileSizeRule() {
      if (this.T1file?.size > 30 * 1024 * 1024) {
        return "File size exceeds the limit (30MB).";
      } else {
        return true;
      }
    },

    userFileSizeRule() {
      if (this.userFile?.size > 30 * 1024 * 1024) {
        return "File size exceeds the limit (30MB).";
      } else {
        return true;
      }
    },
  },
  components: {
    marketingMaterial,
  },
};
</script>
